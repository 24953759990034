import { PipelineTemplatesCategory } from '@redux/types/types';

export const INDEXES_TABLE_PAGE_SIZE = 5;
export const INDEX_PIPELINES_TABLE_PAGE_SIZE = 5;

export const EMPTY_INDEXES_LIST_SECTION = {
  TITLE: 'Indexes',
  DESCRIPTION: [
    {
      paragraph:
        'An Index prepares files for search by converting them into smaller text passages (Documents) and storing them in a DocumentStore. Files are indexed once during deployment, with new files automatically indexed as they are uploaded to your workspace. After creating Indexes, you can link them to the desired pipeline(s). A single Index can be connected to one or multiple pipelines, and multiple Indexes can be used within a single pipeline.',
    },
  ],
};

export const INDEXES_TABLE_COLUMNS_TITLE = {
  NAME: 'Name',
  CREATED_AT: 'Created at',
  CREATED_BY: 'Created by',
  STATUS: 'Status',
};

export const INDEX_STATUS_MESSAGES = {
  INDEXED: 'Indexed',
  NOT_INDEXED: 'Not Indexed',
  IN_PROGRESS: 'Indexing',
  PARTIALLY_INDEXED: 'Partially Indexed',
  FAILED_INDEXING: 'Failed to index',
};
export const INDEXING_STATUS_POPOVER_HEADER = 'File Indexing Status';
export const INDEX_STATUS_POP_CONTENT = {
  PENDING: 'Pending Tasks',
  INDEXED: 'Indexed',
  SKIPPED: 'Skipped',
  FAILED: 'Failed',
  TOTAL: 'Total',
};

export enum IndexesListTab {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DRAFTS = 'DRAFTS',
}

export const INDEXES_LIST_TABS_OPTIONS = [
  { key: IndexesListTab.ALL, label: 'All' },
  { key: IndexesListTab.ACTIVE, label: 'Active' },
  { key: IndexesListTab.DRAFTS, label: 'Drafts' },
];

export enum IndexMenuAction {
  Edit = 'EDIT',
  Enable = 'ENABLE',
  Disable = 'DISABLE',
  Duplicate = 'DUPLICATE',
  Rename = 'RENAME',
  Delete = 'DELETE',
}

export const ENABLE_INDEX_BUTTON_LABEL = 'Enable';
export const DISABLE_INDEX_BUTTON_LABEL = 'Disable';
export const RENAME_INDEX_BUTTON_LABEL = 'Rename';
export const DELETE_INDEX_BUTTON_LABEL = 'Delete';
export const DUPLICATE_INDEX_BUTTON_LABEL = 'Duplicate';

export const INDEX_STATS_LABELS = [
  {
    key: 'pendingFiles',
    label: 'Pending tasks',
    description: `Shows the count of ongoing tasks, like adding files to the document store or updating metadata.`,
  },
  {
    key: 'failedFiles',
    label: 'Failed files',
    description: `The count of files that encountered errors during indexing. For details, see %logs%.`,
  },
  {
    key: 'skippedFiles',
    label: 'Skipped files',
    description: `The number of files the pipeline ignored and didn't index. For details, see %logs%.`,
  },
  {
    key: 'indexedFiles',
    label: 'Indexed files',
    description: `The number of files successfully indexed into the document store.`,
  },
  {
    key: 'totalDocuments',
    label: 'Documents created',
    description: `The count of documents created from the files and indexed into the document store.`,
  },
];

export enum IndexDetailsTab {
  OVERVIEW = 'OVERVIEW',
  LOGS = 'LOGS',
}

export const INDEX_DETAILS_TAB_LABELS = {
  [IndexDetailsTab.OVERVIEW]: 'Overview',
  [IndexDetailsTab.LOGS]: 'Logs',
};

export const CONNECTED_PIPELINES_TITLE = 'Pipelines using this index';

export const CONNECTED_PIPELINES_TABLE_COLUMNS_TITLE = {
  NAME: 'Name',
  SERVICE_LEVEL: 'Service Level',
  CREATED_AT: 'Created at',
  STATUS: 'Status',
  CREATED_BY: 'Created by',
};

export const INDEX_ENABLED_SUCCESS_MESSAGE = 'Index enabled successfully';
export const INDEX_DISABLED_SUCCESS_MESSAGE = 'Index disabled successfully';
export const INDEX_DELETED_SUCCESS_MESSAGE = 'Index deleted successfully';
export const INDEX_LOGS_DOWNLOAD_SUCCESS_MESSAGE =
  'Index logs successfully downloaded to %fileName%';

// create index

// TODO(INDEXES): Add proper categories, added this ones so we can test the flow
export const SIDEBAR_CATEGORIES = [
  PipelineTemplatesCategory.DOCUMENT_SEARCH,
  PipelineTemplatesCategory.BASIC_QA,
];

export const CREATE_INDEX_BUTTON_LABEL = 'Create Index';
export const CREATE_INDEX_MODAL_HEADER = 'Create an Index';
export const CREATE_INDEX_MODAL_SELECT_TEMPLATE_HEADER_DESCRIPTION =
  'Choose a template that matches your file needs. Templates are curated and maintained bydeepset. An index preprocesses all files in your workspace, converts them into documents, and stores those documents in a document store.';
export const CREATE_INDEX_MODAL_BUILD_YOUR_OWN_BUTTON_LABEL = 'Build your own';

export const CREATE_INDEX_MODAL_BUILD_YOUR_OWN_INFO = {
  TITLE: 'Build your own',
  DESCRIPTION: 'Start with an empty canvas and choose the components for your index.',
};

export const CREATE_INDEX_MODAL_FORM = {
  NAME: {
    LABEL: 'Name',
    PLACEHOLDER: 'Enter index name',
  },
  DESCRIPTION: {
    LABEL: 'Description',
    PLACEHOLDER: 'Describe the purpose of this index',
  },
};

export enum CreateIndexModalStep {
  SELECT_TEMPLATE = 'selectTemplate',
  CREATE_INDEX = 'createIndex',
}

// Sorting constants

export const INDEXES_LIST_DEFAULT_SORTING_KEY_ALL_TAB = 'default';

export const INDEXES_LIST_SORTING_DATATABLE_OPTIONS = [
  { key: 'created_at_desc', label: 'Newest to oldest' },
  { key: 'created_at_asc', label: 'Oldest to newest' },
  { key: 'created_by_asc', label: 'Created by ascending' },
  { key: 'created_by_desc', label: 'Created by descending' },
];

export const INDEXES_LIST_SORTING_PARAMS_BY_KEY = {
  default: {
    field: '',
    order: '',
  },
  created_at_desc: {
    field: 'created_at',
    order: 'DESC',
  },
  created_at_asc: {
    field: 'created_at',
    order: 'ASC',
  },
  created_by_asc: {
    field: 'created_by/given_name',
    order: 'ASC',
  },
  created_by_desc: {
    field: 'created_by/given_name',
    order: 'DESC',
  },
};

// Index confirm action modal
export const CONFIRM_MODAL_LABELS = {
  [IndexMenuAction.Delete]: {
    MODAL_TITLE: 'Delete Index',
    MODAL_TITLE_ALTERNATE: 'Cannot delete index',
    PRIMARY_BUTTON_LABEL: 'Delete',
    PRIMARY_BUTTON_LABEL_ALTERNATE: 'Got it',
    DESCRIPTION:
      'Deleting the %indexName% is permanent. Pipelines using this index will lose access to its data.',
    DESCRIPTION_ALTERNATE:
      'Deleting this index could disrupt active pipelines that are using it. To delete, remove the index from those pipelines or undeploy them.',
  },
  [IndexMenuAction.Disable]: {
    MODAL_TITLE: 'Disable Index',
    MODAL_TITLE_ALTERNATE: 'Cannot disable index',
    PRIMARY_BUTTON_LABEL: 'Disable',
    PRIMARY_BUTTON_LABEL_ALTERNATE: 'Got it',
    DESCRIPTION:
      'Disabling the %indexName% removes all indexed data. If you re-enable it, indexing will start from the beginning and must finish before pipelines can search the data.',
    DESCRIPTION_ALTERNATE:
      'Disabling this index could disrupt active pipelines that are using it. To disable, remove the index from those pipelines or undeploy them.',
  },
};

export const CONFIRM_MODAL_PIPELINES_PAGE_SIZE = 5;
export const CONFIRM_MODAL_PIPELINES_EMPTY_LABEL = 'There are no pipelines using this index.';
export const CONFIRM_MODAL_INDEX_NAME_INPUT_LABEL = 'Type the index name to confirm:';

// Rename index
export const INDEX_NAME_MODAL_LABELS = {
  MODAL_TITLE: 'Rename Index',
  NAME_INPUT_LABEL: 'Index name',
  OK_BUTTON_TEXT: 'Done',
};
