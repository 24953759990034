import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CloseCircleFilled,
  DownloadOutlined,
  InfoCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import { Button, Collapse, Divider, Empty, Skeleton, Switch } from 'antd';
import dayjs from 'dayjs';
import { formatDateFilter, resetFilterValues } from '@utils/dataFilters';
import { interpolateString } from '@utils/string';
import { FiltersProp, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import {
  CLEAR_ALL_FILTERS_BUTTON_LABEL,
  EMPTY_LOGS_DEPLOYED_PIPELINE_LABEL,
  EMPTY_LOGS_UNDEPLOYED_PIPELINE_LABEL,
  EMPTY_LOGS_WITH_FILTERS_LABEL,
  EMPTY_LOGS_WITH_SEARCH_VALUE_LABEL,
  ENABLE_AUTO_REFRESH_BUTTON_LABEL,
  ILogEntry,
  ILogParams,
  LOG_DATE_FILTER,
  LOG_LEVEL_FILTER,
  LOG_ORIGIN_FILTER,
  LogLevels,
  LOGS_API_DEFAULT_LIMIT,
  LOGS_DOWNLOAD_BUTTON_LABEL,
  LOGS_SEARCH_PLACEHOLDER_LABEL,
  TOTAL_LOGS,
  TOTAL_LOGS_PER_PAGE,
} from '@constants/log-explorer';
import { PipelineStatusCodes } from '@constants/pipelines';
import { IAPIPaginationData } from '@redux/types/types';
import AppliedFiltersList from '@components/appliedFilterList/AppliedFiltersList';
import DataHeaderActions from '@components/dataHeaderActions/DataHeaderActions';
import LogContent from './logContent/LogContent';
import styles from './logExplorer.module.scss';

interface ILogExplorerProps {
  status: PipelineStatusCodes;
  logs: IAPIPaginationData<ILogEntry[]>;
  getData: (params: ILogParams) => void;
  exportData: (params?: ILogParams) => void;
  polling: {
    start: (params: ILogParams) => void;
    stop: () => void;
  };
  loading?: boolean;
  hasOriginFilter?: boolean;
}

const { Panel } = Collapse;
const POLL_LOGS_INTERVAL_MILLISECONDS = 8000;

const LogExplorer: React.FC<ILogExplorerProps> = (props) => {
  const {
    status,
    loading,
    getData,
    exportData,
    hasOriginFilter,
    logs: { data: logData, has_more: hasMore, total: totalNumberOfLogs },
    polling: { start: startPolling, stop: stopPolling },
  } = props;

  const defaultFilters = hasOriginFilter
    ? [LOG_DATE_FILTER, LOG_ORIGIN_FILTER, LOG_LEVEL_FILTER]
    : [LOG_DATE_FILTER, LOG_LEVEL_FILTER];

  const [searchValue, setSearchValue] = useState<string>('');
  const [enableAutoRefresh, setEnableAutoRefresh] = useState(false);
  const [filters] = useState<FiltersProp>(defaultFilters);
  const [filterValues, setFilterValues] = useState<SelectedFilters>({});

  const emptyLogsMessage =
    status === PipelineStatusCodes.DEPLOYED
      ? EMPTY_LOGS_DEPLOYED_PIPELINE_LABEL
      : EMPTY_LOGS_UNDEPLOYED_PIPELINE_LABEL;

  const getLogFilterParameters = (): ILogParams => {
    const dateItem = filterValues && filterValues.logged_at && filterValues.logged_at.length > 0;
    return {
      searchValue,
      filterValues: dateItem ? filterValues : { ...filterValues },
      limit: LOGS_API_DEFAULT_LIMIT,
    };
  };

  useEffect(() => {
    getData(getLogFilterParameters());

    stopPolling();
    if (enableAutoRefresh) {
      startPolling(getLogFilterParameters());
    }

    return () => {
      stopPolling();
    };
  }, [enableAutoRefresh, filterValues, searchValue]);

  const onFilterSelectChange = (filterKey: string, items: SelectedFilterItem[]) => {
    if (filterKey === 'logged_at' && items.length > 0) {
      setFilterValues((values) => ({ ...values, [filterKey]: [formatDateFilter(items[0])] }));
    } else {
      setFilterValues((values) => ({ ...values, [filterKey]: items }));
    }
  };

  const onClearAllFilters = () => {
    setFilterValues({ ...resetFilterValues(filters) });
  };

  const getLogIcon = (logLevel: string) => {
    if (logLevel === LogLevels.WARNING)
      return <WarningFilled className={styles.pipelineLogs_warningIcon} />;
    if (logLevel === LogLevels.ERROR || logLevel === LogLevels.CRITICAL)
      return <CloseCircleFilled className={styles.pipelineLogs_errorIcon} />;
    return <InfoCircleFilled className={styles.pipelineLogs_infoIcon} />;
  };

  const getLogBackground = (logLevel: string) => {
    if (logLevel === LogLevels.WARNING) return styles.pipelineLogs_warningBackground;
    if (logLevel === LogLevels.ERROR || logLevel === LogLevels.CRITICAL)
      return styles.pipelineLogs_errorBackground;
    return styles.pipelineLogs_infoBackground;
  };

  const renderSkeletonLogItem = () => (
    <div className={styles.pipelineLogSkeleton}>
      <Skeleton paragraph={{ rows: 1, width: '100%' }} active title={false} />
    </div>
  );

  const renderEmptyLogsWithFilters = () => {
    return logData.length === 0 && searchValue !== '' ? (
      <div className={styles.emptyLogs}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{EMPTY_LOGS_WITH_SEARCH_VALUE_LABEL}</span>}
        />
      </div>
    ) : (
      <div className={styles.emptyLogs}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{EMPTY_LOGS_WITH_FILTERS_LABEL}</span>}
        />
        <Button type="primary" onClick={() => onClearAllFilters()}>
          {CLEAR_ALL_FILTERS_BUTTON_LABEL}
        </Button>
      </div>
    );
  };

  const getFadeInAnimation = (log: ILogEntry) => {
    const dateDiff = dayjs().diff(dayjs(log.logged_at));
    if (dateDiff < POLL_LOGS_INTERVAL_MILLISECONDS && enableAutoRefresh) return styles.fadeInLog;
    return '';
  };

  const renderLogsList = useMemo(
    () => (
      <>
        <div className={styles.pipelineLogs}>
          <div className={styles.pipelineLogs_firstAction}>
            <DataHeaderActions
              onSearch={(value) => setSearchValue(value)}
              filters={filters}
              filterValues={filterValues}
              loading={loading}
              onFilterSelectChange={onFilterSelectChange}
              primaryAction={{
                label: LOGS_DOWNLOAD_BUTTON_LABEL,
                onClick: () => exportData(getLogFilterParameters()),
                disabled: logData.length === 0,
                secondary: true,
                icon: <DownloadOutlined />,
              }}
              searchAvailable
              searchPlaceholder={LOGS_SEARCH_PLACEHOLDER_LABEL}
            />
          </div>
          <Divider className={styles.filtersDivider} />
          <div className={styles.pipelineLogs_secondAction}>
            <div className={styles.totalItems}>
              <div className={styles.totalItems_label}>
                <span className={styles.totalIGtems_label_subtitle}>
                  {interpolateString(TOTAL_LOGS_PER_PAGE, {
                    totalPerPage: logData.length,
                  })}
                </span>
                <span className={styles.totalItems_label_total}>
                  {interpolateString(TOTAL_LOGS, {
                    totalItems: totalNumberOfLogs,
                  })}
                </span>
              </div>
              <AppliedFiltersList
                filterValues={filterValues}
                onClearAllFilters={onClearAllFilters}
                onFilterSelectChange={onFilterSelectChange}
              />
            </div>
            <div className={styles.pipelineLogs_secondAction_enableAutoRefresh}>
              <span>{ENABLE_AUTO_REFRESH_BUTTON_LABEL}</span>
              <Switch size="small" onChange={(e) => setEnableAutoRefresh(e)} />
            </div>
          </div>
          <Divider className={styles.horizontalDivider} />
          <div className={styles.pipelineLogs_collapse}>
            <InfiniteScroll
              dataLength={logData.length}
              next={() =>
                getData({
                  filterValues,
                  after: logData.length ? logData[logData.length - 1].logged_at : undefined,
                  limit: LOGS_API_DEFAULT_LIMIT,
                  fetchMore: true,
                })
              }
              loader={renderSkeletonLogItem()}
              hasMore={hasMore}
              scrollableTarget="contentAppSection"
            >
              <Collapse bordered={false} size="small" expandIconPosition="end" collapsible="header">
                {logData.length > 0
                  ? logData?.map((log) => {
                      return (
                        <Panel
                          className={`${getLogBackground(log.level)} ${getFadeInAnimation(log)}`}
                          header={
                            <>
                              {getLogIcon(log.level)}
                              <span className={styles.pipelineLogs_logTitle}>{log.message}</span>
                            </>
                          }
                          extra={
                            <span className={styles.pipelineLogs_logDate}>
                              {new Date(log.logged_at).toLocaleString()}
                            </span>
                          }
                          key={log.log_id}
                        >
                          <LogContent log={log} />
                        </Panel>
                      );
                    })
                  : renderEmptyLogsWithFilters()}
              </Collapse>
            </InfiniteScroll>
          </div>
        </div>
      </>
    ),
    [logData, hasMore, filterValues, filters],
  );

  return !logData ||
    (logData.length === 0 && searchValue === '' && Object.keys(filterValues).length === 0) ||
    status === PipelineStatusCodes.UNDEPLOYED ? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyLogsMessage}</span>} />
  ) : (
    renderLogsList
  );
};

export default LogExplorer;
