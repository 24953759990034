/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStatusCode, handleErrorNotification, normalizeErrorMessage } from '@utils/error';
import { downloadBlob } from '@utils/file';
import {
  getODataConjunctionFilterFrom,
  getODataEQFilterFrom,
  getODataFilterFrom,
  getODataSearchFilterFrom,
} from '@utils/odata';
import { interpolateString } from '@utils/string';
import { SelectedFilters } from '@constants/data-table';
import { HTTPStatusCode, MessageCodes, StatusCodes } from '@constants/enum/common';
import { ILogEntry, ILogParams } from '@constants/log-explorer';
import { PipelineDesiredStatusCodes, PipelineMessages } from '@constants/pipelines';
import { addNotification } from '@redux/actions/notificationActions';
import { AppDispatch, RootState } from '@redux/store';
import { IAPIPaginationData, IMessage, IPipeline, NotificationType } from '@redux/types/types';
import {
  createIndexAPI,
  deleteIndexAPI,
  disableIndexAPI,
  downloadIndexLogsAPI,
  enableIndexAPI,
  getConnectedPipelinesAPI,
  getIndexAPI,
  getIndexesAPI,
  getIndexLogsAPI,
  getIndexTotalDocumentsAPI,
  ICreateIndexPayload,
  IIndexPipelinesParams,
  updateIndexAPI,
} from '../api/indexes';
import {
  INDEX_DELETED_SUCCESS_MESSAGE,
  INDEX_DISABLED_SUCCESS_MESSAGE,
  INDEX_ENABLED_SUCCESS_MESSAGE,
  INDEX_LOGS_DOWNLOAD_SUCCESS_MESSAGE,
  INDEX_PIPELINES_TABLE_PAGE_SIZE,
  INDEXES_LIST_DEFAULT_SORTING_KEY_ALL_TAB,
  INDEXES_LIST_SORTING_PARAMS_BY_KEY,
  INDEXES_TABLE_PAGE_SIZE,
  IndexesListTab,
} from '../constants/indexes';
import { AsyncActionType } from '../constants/redux';
import { IPipelineIndex } from '../types/pipeline-index';

export type PollingKey = string;

export const POLLING_KEYS = {
  INDEXES: 'indexes',
  INDEX_DETAILS: 'indexDetails',
  INDEX_LOGS: 'indexLogs',
} as const;

interface IndexesState {
  indexes: IAPIPaginationData<IPipelineIndex[]>;
  activeIndexesListTab: IndexesListTab;
  index: IPipelineIndex;
  indexTotalDocuments: number;
  sortValueIndexesList: string;
  fetchIndexesStatus: StatusCodes;
  createIndexStatus: StatusCodes;
  fetchIndexStatus: StatusCodes;
  updateIndexStatus: StatusCodes;
  updateIndexYamlStatus: StatusCodes;
  enableIndexStatus: StatusCodes;
  disableIndexStatus: StatusCodes;
  deleteIndexStatus: StatusCodes;
  fetchIndexTotalDocumentsStatus: StatusCodes;
  connectedPipelines: IAPIPaginationData<IPipeline[]>;
  fetchConnectedPipelinesStatus: StatusCodes;
  indexLogs: IAPIPaginationData<ILogEntry[]>;
  fetchIndexLogsStatus: StatusCodes;
  indexLogsDownloadStatus: StatusCodes;
  pollingIds: Record<PollingKey, NodeJS.Timeout | null>;
  pollingIntervals: Record<PollingKey, number>;
  message: IMessage;
}

export const initialState: IndexesState = {
  indexes: {
    data: [],
    has_more: false,
    total: 0,
  },
  activeIndexesListTab: IndexesListTab.ALL,
  index: {
    pipeline_index_id: '',
    name: '',
    config_yaml: '',
    workspace_id: '',
    settings: {},
    desired_status: PipelineDesiredStatusCodes.DEPLOYED,
    status: {
      failed_file_count: 0,
      indexed_file_count: 0,
      indexed_no_documents_file_count: 0,
      pending_file_count: 0,
      total_file_count: 0,
    },
    max_index_replica_count: 0,
    created_at: '',
    updated_at: '',
    pipelines: [],
  },
  indexTotalDocuments: 0,
  sortValueIndexesList: INDEXES_LIST_DEFAULT_SORTING_KEY_ALL_TAB,
  connectedPipelines: {
    data: [],
    has_more: false,
    total: 0,
  },
  indexLogs: {
    data: [],
    has_more: false,
    total: 0,
  },
  fetchIndexLogsStatus: StatusCodes.IDLE,
  indexLogsDownloadStatus: StatusCodes.IDLE,
  fetchConnectedPipelinesStatus: StatusCodes.IDLE,
  fetchIndexesStatus: StatusCodes.IDLE,
  fetchIndexStatus: StatusCodes.IDLE,
  createIndexStatus: StatusCodes.IDLE,
  updateIndexStatus: StatusCodes.IDLE,
  updateIndexYamlStatus: StatusCodes.IDLE,
  enableIndexStatus: StatusCodes.IDLE,
  disableIndexStatus: StatusCodes.IDLE,
  deleteIndexStatus: StatusCodes.IDLE,
  fetchIndexTotalDocumentsStatus: StatusCodes.IDLE,
  pollingIds: {
    [POLLING_KEYS.INDEXES]: null,
    [POLLING_KEYS.INDEX_LOGS]: null,
    [POLLING_KEYS.INDEX_DETAILS]: null,
  },
  pollingIntervals: {
    [POLLING_KEYS.INDEXES]: 10_000,
    [POLLING_KEYS.INDEX_LOGS]: 10_000,
    [POLLING_KEYS.INDEX_DETAILS]: 10_000,
  },
  message: {
    content: '',
    type: MessageCodes.INFO,
  },
};

export const fetchIndexes = createAsyncThunk(
  AsyncActionType.FETCH_INDEXES,
  async (
    {
      limit,
      pageNumber,
      desiredStatus,
      searchValue,
      sortValue,
      filterValues,
    }: {
      limit?: number;
      pageNumber?: number;
      desiredStatus?: PipelineDesiredStatusCodes;
      searchValue?: string;
      sortValue?: string;
      filterValues?: SelectedFilters;
      isPollRequest?: boolean;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState() as RootState;
    const { sortValueIndexesList } = state.indexesStore;
    const sortingKey = (sortValue ||
      sortValueIndexesList) as keyof typeof INDEXES_LIST_SORTING_PARAMS_BY_KEY;
    const { field, order } = INDEXES_LIST_SORTING_PARAMS_BY_KEY[sortingKey] || {};

    const searchFilter = searchValue && getODataSearchFilterFrom('name', searchValue);
    const desiredStatusFilter =
      desiredStatus && getODataEQFilterFrom('desired_status', desiredStatus);
    const filters = filterValues && getODataFilterFrom(filterValues);

    const params = {
      page_number: pageNumber,
      limit: limit || INDEXES_TABLE_PAGE_SIZE,
      filter: getODataConjunctionFilterFrom(searchFilter, filters, desiredStatusFilter),
      field,
      order,
    };

    try {
      const { data } = await getIndexesAPI<IAPIPaginationData<IPipelineIndex[]>>(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startPollingIndexes =
  ({
    limit,
    pageNumber,
    desiredStatus,
    searchValue,
    sortValue,
    filterValues,
    interval,
    pollingKey = POLLING_KEYS.INDEXES,
  }: {
    limit?: number;
    pageNumber?: number;
    desiredStatus?: PipelineDesiredStatusCodes;
    searchValue?: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
    interval?: number;
    pollingKey?: PollingKey;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { pollingIds, pollingIntervals } = getState().indexesStore;
    const pollingId = pollingIds[pollingKey];

    if (pollingId !== null) dispatch(stopPolling(pollingKey));

    if (interval) dispatch(setPollingInterval({ key: pollingKey, interval }));

    const effectiveInterval = interval || pollingIntervals[pollingKey];
    const intervalId = setInterval(() => {
      dispatch(
        fetchIndexes({
          limit,
          pageNumber,
          desiredStatus,
          searchValue,
          sortValue,
          filterValues,
          isPollRequest: true,
        }),
      );
    }, effectiveInterval);

    dispatch(setPollingId({ key: pollingKey, id: intervalId }));
  };

export const fetchConnectedPipelines = createAsyncThunk(
  AsyncActionType.FETCH_CONNECTED_PIPELINES,
  async (payload: IIndexPipelinesParams, { rejectWithValue }) => {
    const params = {
      ...payload,
      limit: payload.limit || INDEX_PIPELINES_TABLE_PAGE_SIZE,
    };

    try {
      const { data } = await getConnectedPipelinesAPI<IAPIPaginationData<IPipeline[]>>(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchIndex = createAsyncThunk(
  AsyncActionType.FETCH_INDEX,
  async ({ indexName }: { indexName: string; isPollRequest?: boolean }, { rejectWithValue }) => {
    try {
      const { data } = await getIndexAPI<IPipelineIndex>(indexName);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startPollingIndex = (indexName: string) => (dispatch: AppDispatch, getState: any) => {
  const { pollingIds, pollingIntervals } = getState().indexesStore;
  const pollingKey = POLLING_KEYS.INDEX_DETAILS;
  const pollingId = pollingIds[pollingKey];

  if (pollingId !== null) dispatch(stopPolling(pollingKey));

  const intervalId = setInterval(() => {
    dispatch(fetchIndex({ indexName, isPollRequest: true }));
  }, pollingIntervals[pollingKey]);

  dispatch(setPollingId({ key: pollingKey, id: intervalId }));
};

export const createIndex = createAsyncThunk(
  AsyncActionType.CREATE_INDEX,
  async (payload: ICreateIndexPayload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await createIndexAPI(payload);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const updateIndex = createAsyncThunk(
  AsyncActionType.UPDATE_INDEX,
  async (
    { indexName, payload }: { indexName: string; payload: Partial<IPipelineIndex> },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data } = await updateIndexAPI(indexName, payload);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const updateIndexYaml = createAsyncThunk(
  AsyncActionType.UPDATE_INDEX_YAML,
  async (
    { indexName, yaml }: { indexName: string; yaml: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { data } = await updateIndexAPI(indexName, { config_yaml: yaml });
      dispatch(
        addNotification({
          content: PipelineMessages.SAVE_SUCCESS,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const enableIndex = createAsyncThunk(
  AsyncActionType.ENABLE_INDEX,
  async (indexName: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await enableIndexAPI(indexName);
      dispatch(
        addNotification({
          content: INDEX_ENABLED_SUCCESS_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const disableIndex = createAsyncThunk(
  AsyncActionType.DISABLE_INDEX,
  async (indexName: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await disableIndexAPI(indexName);
      dispatch(
        addNotification({
          content: INDEX_DISABLED_SUCCESS_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const fetchIndexTotalDocuments = createAsyncThunk(
  AsyncActionType.FETCH_INDEX_TOTAL_DOCUMENTS,
  async (indexName: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { fetchIndexTotalDocumentsStatus, indexTotalDocuments } = state.indexesStore;

    if (fetchIndexTotalDocumentsStatus === StatusCodes.IN_PROGRESS) return;

    try {
      const {
        data: { count },
      } = await getIndexTotalDocumentsAPI<{ count: number }>(indexName);
      return count;
    } catch (error) {
      // The API is a bit flaky, so we return the previous value if the API returns a 404 or 500
      const status = getStatusCode(error);
      if (status === HTTPStatusCode.NOT_FOUND || status === HTTPStatusCode.INTERNAL_SERVER_ERROR) {
        return indexTotalDocuments;
      }

      return rejectWithValue(error);
    }
  },
);

export const deleteIndex = createAsyncThunk(
  AsyncActionType.DELETE_INDEX,
  async (name: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteIndexAPI<void>(name);
      dispatch(
        addNotification({
          content: INDEX_DELETED_SUCCESS_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const fetchIndexLogs = createAsyncThunk(
  AsyncActionType.FETCH_INDEX_LOGS,
  async (
    params: ILogParams & { indexName: string; isPollRequest?: boolean },
    { rejectWithValue, dispatch },
  ) => {
    const { indexName, isPollRequest, filterValues, searchValue, ...queryParams } = params;

    const filters = filterValues && getODataFilterFrom(filterValues);
    const searchFilter = searchValue && getODataSearchFilterFrom('message', searchValue);

    const paramsApi = {
      filter: getODataConjunctionFilterFrom(searchFilter, filters),
      ...queryParams,
    };

    try {
      const { data } = await getIndexLogsAPI<IAPIPaginationData<ILogEntry[]>>(indexName, paramsApi);
      return data;
    } catch (error) {
      if (!isPollRequest) handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const startPollingIndexLogs =
  (params: ILogParams & { indexName: string }) => (dispatch: AppDispatch, getState: any) => {
    const { pollingIds, pollingIntervals } = getState().indexesStore;
    const pollingKey = POLLING_KEYS.INDEX_LOGS;
    const pollingId = pollingIds[pollingKey];

    if (pollingId !== null) dispatch(stopPolling(pollingKey));

    const intervalId = setInterval(() => {
      dispatch(fetchIndexLogs({ ...params, isPollRequest: true }));
    }, pollingIntervals[pollingKey]);

    dispatch(setPollingId({ key: pollingKey, id: intervalId }));
  };

export const downloadIndexLogs = createAsyncThunk(
  AsyncActionType.DOWNLOAD_INDEX_LOGS,
  async (indexName: string, { rejectWithValue, dispatch }) => {
    try {
      const now = new Date().toISOString();
      const fileName = `logs_${indexName}_${now}.csv`;
      const { data } = await downloadIndexLogsAPI<Blob>(indexName);

      downloadBlob(fileName, data as Blob);

      dispatch(
        addNotification({
          content: interpolateString(
            INDEX_LOGS_DOWNLOAD_SUCCESS_MESSAGE,
            { fileName },
            false,
          ) as string,
          type: NotificationType.Success,
        }),
      );

      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const indexesSlice = createSlice({
  name: 'indexes',
  initialState,
  reducers: {
    setActiveIndexesListTab: (state, action: PayloadAction<IndexesListTab>) => {
      state.activeIndexesListTab = action.payload;
    },
    setSortValueIndexesList: (state, action: PayloadAction<string>) => {
      state.sortValueIndexesList = action.payload;
    },
    resetUpdateIndexStatus: (state) => {
      state.message = initialState.message;
      state.updateIndexStatus = StatusCodes.IDLE;
    },
    setIndexYaml: (state, action: PayloadAction<{ yaml: string }>) => {
      const { yaml } = action.payload;
      state.index.config_yaml = yaml;
    },
    setPollingId: (
      state,
      action: PayloadAction<{ key: PollingKey; id: NodeJS.Timeout | null }>,
    ) => {
      const { key, id } = action.payload;
      state.pollingIds[key] = id;
    },
    setPollingInterval: (state, action: PayloadAction<{ key: PollingKey; interval: number }>) => {
      const { key, interval } = action.payload;
      state.pollingIntervals[key] = interval;
    },
    stopPolling: (state, action: PayloadAction<PollingKey>) => {
      const pollingKey = action.payload;
      const pollingId = state.pollingIds[pollingKey];

      if (pollingId !== null) {
        clearInterval(pollingId);
        state.pollingIds[pollingKey] = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndexes.pending, (state, action) => {
        const { isPollRequest } = action.meta.arg;

        if (!isPollRequest) state.fetchIndexesStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(fetchIndexes.fulfilled, (state, action) => {
        state.fetchIndexesStatus = StatusCodes.SUCCESS;
        state.indexes = action.payload;
      })
      .addCase(fetchIndexes.rejected, (state) => {
        state.fetchIndexesStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(fetchIndex.pending, (state, action) => {
        const { isPollRequest } = action.meta.arg;
        if (!isPollRequest) state.fetchIndexStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(fetchIndex.fulfilled, (state, action) => {
        state.fetchIndexStatus = StatusCodes.SUCCESS;
        state.index = action.payload;
      })
      .addCase(fetchIndex.rejected, (state) => {
        state.fetchIndexStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(createIndex.pending, (state) => {
        state.createIndexStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(createIndex.fulfilled, (state) => {
        state.createIndexStatus = StatusCodes.SUCCESS;
      })
      .addCase(createIndex.rejected, (state) => {
        state.createIndexStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(updateIndex.pending, (state) => {
        state.updateIndexStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(updateIndex.fulfilled, (state, action) => {
        const { indexName: oldName } = action.meta.arg;

        state.updateIndexStatus = StatusCodes.SUCCESS;
        state.index = action.payload as IPipelineIndex;
        state.indexes.data = state.indexes.data.map((index) =>
          index.name === oldName ? { ...state.index } : index,
        );
      })
      .addCase(updateIndex.rejected, (state, action) => {
        state.updateIndexStatus = StatusCodes.ERROR;

        const status = getStatusCode(action.payload);
        if (status === HTTPStatusCode.CONFLICT) {
          state.message = {
            type: MessageCodes.ERROR,
            content: normalizeErrorMessage(action.payload),
          };
        }
      });

    builder
      .addCase(updateIndexYaml.pending, (state) => {
        state.updateIndexYamlStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(updateIndexYaml.fulfilled, (state, action) => {
        state.updateIndexYamlStatus = StatusCodes.SUCCESS;
        state.index = action.payload as IPipelineIndex;
      })
      .addCase(updateIndexYaml.rejected, (state) => {
        state.updateIndexYamlStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(enableIndex.pending, (state) => {
        state.enableIndexStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(enableIndex.fulfilled, (state, action) => {
        state.enableIndexStatus = StatusCodes.SUCCESS;
        state.index = action.payload as IPipelineIndex;
      })
      .addCase(enableIndex.rejected, (state) => {
        state.enableIndexStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(disableIndex.pending, (state) => {
        state.disableIndexStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(disableIndex.fulfilled, (state, action) => {
        state.disableIndexStatus = StatusCodes.SUCCESS;
        state.index = action.payload as IPipelineIndex;
      })
      .addCase(disableIndex.rejected, (state) => {
        state.disableIndexStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(fetchIndexTotalDocuments.pending, (state) => {
        state.fetchIndexTotalDocumentsStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(fetchIndexTotalDocuments.fulfilled, (state, action) => {
        state.fetchIndexTotalDocumentsStatus = StatusCodes.SUCCESS;
        state.indexTotalDocuments = action.payload ?? initialState.indexTotalDocuments;
      })
      .addCase(fetchIndexTotalDocuments.rejected, (state) => {
        state.fetchIndexTotalDocumentsStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(fetchConnectedPipelines.pending, (state) => {
        state.fetchConnectedPipelinesStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(fetchConnectedPipelines.fulfilled, (state, action) => {
        state.fetchConnectedPipelinesStatus = StatusCodes.SUCCESS;
        state.connectedPipelines = action.payload;
      })
      .addCase(fetchConnectedPipelines.rejected, (state) => {
        state.fetchConnectedPipelinesStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(deleteIndex.pending, (state) => {
        state.deleteIndexStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(deleteIndex.fulfilled, (state, action) => {
        const indexName = action.meta.arg;
        state.deleteIndexStatus = StatusCodes.SUCCESS;
        state.indexes.data = [...state.indexes.data.filter((index) => index.name !== indexName)];
      })
      .addCase(deleteIndex.rejected, (state) => {
        state.deleteIndexStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(fetchIndexLogs.pending, (state, action) => {
        const { isPollRequest } = action.meta.arg;
        if (!isPollRequest) state.fetchIndexLogsStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(fetchIndexLogs.fulfilled, (state, action) => {
        const { fetchMore } = action.meta.arg;

        if (fetchMore)
          state.indexLogs = {
            ...action.payload,
            data: [...state.indexLogs.data, ...action.payload.data],
          };
        else state.indexLogs = action.payload;
        state.fetchIndexLogsStatus = StatusCodes.SUCCESS;
      })
      .addCase(fetchIndexLogs.rejected, (state) => {
        state.fetchIndexLogsStatus = StatusCodes.ERROR;
      });

    builder
      .addCase(downloadIndexLogs.pending, (state) => {
        state.indexLogsDownloadStatus = StatusCodes.IN_PROGRESS;
      })
      .addCase(downloadIndexLogs.fulfilled, (state) => {
        state.indexLogsDownloadStatus = StatusCodes.SUCCESS;
      })
      .addCase(downloadIndexLogs.rejected, (state) => {
        state.indexLogsDownloadStatus = StatusCodes.ERROR;
      });
  },
});

export const {
  setActiveIndexesListTab,
  setSortValueIndexesList,
  resetUpdateIndexStatus,
  setPollingId,
  setPollingInterval,
  stopPolling,
  setIndexYaml,
} = indexesSlice.actions;

export default indexesSlice.reducer;

// SELECTORS
const selectIndexesState = (state: RootState) => state.indexesStore;
const getIndexes = (state: RootState) => selectIndexesState(state).indexes;
const getIndex = (state: RootState) => selectIndexesState(state).index;
const getIndexTotalDocuments = (state: RootState) => selectIndexesState(state).indexTotalDocuments;
const getFetchIndexesStatus = (state: RootState) => selectIndexesState(state).fetchIndexesStatus;
const getFetchIndexStatus = (state: RootState) => selectIndexesState(state).fetchIndexStatus;
const getFetchIndexTotalDocumentsStatus = (state: RootState) =>
  selectIndexesState(state).fetchIndexTotalDocumentsStatus;
const getActiveIndexesListTab = (state: RootState) =>
  selectIndexesState(state).activeIndexesListTab;
const getCreateIndexStatus = (state: RootState) => selectIndexesState(state).createIndexStatus;
const getSortValueIndexesList = (state: RootState) =>
  selectIndexesState(state).sortValueIndexesList;
const getConnectedPipelines = (state: RootState) => selectIndexesState(state).connectedPipelines;
const getFetchConnectedPipelinesStatus = (state: RootState) =>
  selectIndexesState(state).fetchConnectedPipelinesStatus;
const getUpdateIndexStatus = (state: RootState) => selectIndexesState(state).updateIndexStatus;
const getUpdateIndexYamlStatus = (state: RootState) =>
  selectIndexesState(state).updateIndexYamlStatus;
const getEnableIndexStatus = (state: RootState) => selectIndexesState(state).enableIndexStatus;
const getDisableIndexStatus = (state: RootState) => selectIndexesState(state).disableIndexStatus;
const getDeleteIndexStatus = (state: RootState) => selectIndexesState(state).deleteIndexStatus;
const getIndexLogs = (state: RootState) => selectIndexesState(state).indexLogs;
const getFetchIndexLogsStatus = (state: RootState) =>
  selectIndexesState(state).fetchIndexLogsStatus;
const getIndexLogsDownloadStatus = (state: RootState) =>
  selectIndexesState(state).indexLogsDownloadStatus;
const getPollingIds = (state: RootState) => selectIndexesState(state).pollingIds;
const getPollingIntervals = (state: RootState) => selectIndexesState(state).pollingIntervals;
const getMessage = (state: RootState) => selectIndexesState(state).message;

export const selectIndexes = createSelector([getIndexes], (indexes) => indexes);
export const selectIndex = createSelector([getIndex], (index) => index);
export const selectFetchIndexesStatus = createSelector(
  [getFetchIndexesStatus],
  (fetchIndexesStatus) => fetchIndexesStatus,
);
export const selectFetchIndexStatus = createSelector(
  [getFetchIndexStatus],
  (fetchIndexStatus) => fetchIndexStatus,
);
export const selectActiveIndexesListTab = createSelector(
  [getActiveIndexesListTab],
  (activeIndexesListTab) => activeIndexesListTab,
);
export const selectCreateIndexStatus = createSelector(
  [getCreateIndexStatus],
  (createIndexStatus) => createIndexStatus,
);
export const selectSortValueIndexesList = createSelector(
  [getSortValueIndexesList],
  (sortValueIndexesList) => sortValueIndexesList,
);
export const selectIndexStats = createSelector(
  [getIndex, getIndexTotalDocuments],
  (index, indexTotalDocuments) => ({
    indexedFiles: index.status.indexed_file_count,
    pendingFiles: index.status.pending_file_count,
    failedFiles: index.status.failed_file_count,
    totalFiles: index.status.total_file_count,
    skippedFiles: index.status.indexed_no_documents_file_count,
    totalDocuments: indexTotalDocuments,
  }),
);
export const selectFetchIndexTotalDocumentsStatus = createSelector(
  [getFetchIndexTotalDocumentsStatus],
  (fetchIndexTotalDocumentsStatus) => fetchIndexTotalDocumentsStatus,
);
export const selectConnectedPipelines = createSelector(
  [getConnectedPipelines],
  (connectedPipelines) => connectedPipelines,
);
export const selectFetchConnectedPipelinesStatus = createSelector(
  [getFetchConnectedPipelinesStatus],
  (fetchConnectedPipelinesStatus) => fetchConnectedPipelinesStatus,
);
export const selectUpdateIndexStatus = createSelector(
  [getUpdateIndexStatus],
  (updateIndexStatus) => updateIndexStatus,
);
export const selectUpdateIndexYamlStatus = createSelector(
  [getUpdateIndexYamlStatus],
  (updateIndexYamlStatus) => updateIndexYamlStatus,
);
export const selectEnableIndexStatus = createSelector(
  [getEnableIndexStatus],
  (enableIndexStatus) => enableIndexStatus,
);
export const selectDisableIndexStatus = createSelector(
  [getDisableIndexStatus],
  (disableIndexStatus) => disableIndexStatus,
);
export const selectDeleteIndexStatus = createSelector(
  [getDeleteIndexStatus],
  (deleteIndexStatus) => deleteIndexStatus,
);
export const selectIndexLogs = createSelector([getIndexLogs], (indexLogs) => indexLogs);
export const selectFetchIndexLogsStatus = createSelector(
  [getFetchIndexLogsStatus],
  (fetchIndexLogsStatus) => fetchIndexLogsStatus,
);
export const selectIndexLogsDownloadStatus = createSelector(
  [getIndexLogsDownloadStatus],
  (indexLogsDownloadStatus) => indexLogsDownloadStatus,
);
export const selectPollingId = createSelector(
  [getPollingIds, (_state: RootState, key: PollingKey = POLLING_KEYS.INDEXES) => key],
  (pollingIds, key) => pollingIds[key],
);
export const selectPollingInterval = createSelector(
  [getPollingIntervals, (_state: RootState, key: PollingKey = POLLING_KEYS.INDEXES) => key],
  (pollingIntervals, key) => pollingIntervals[key],
);
export const selectIsPolling = createSelector(
  [getPollingIds, (_state: RootState, key: PollingKey = POLLING_KEYS.INDEXES) => key],
  (pollingIds, key) => pollingIds[key] !== null,
);
export const selectIsAnyPolling = createSelector([getPollingIds], (pollingIds) =>
  Object.values(pollingIds).some((id) => id !== null),
);
export const selectMessage = createSelector([getMessage], (message) => message);
