import produce from 'immer';
import jwt from 'jwt-decode';
// import { DEFAULT_WORKSPACE } from '@constants/workspace';
import { ISharedPrototypeTokenData, SET_SHARED_PROTOTYPE_TOKEN_DATA } from '@redux/types/types';

interface IInitialStateProps {
  tokenData: ISharedPrototypeTokenData | null;
}

export const initialState: IInitialStateProps = {
  tokenData: null,
};

function sharedPrototypeReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;

    switch (action.type) {
      case `${SET_SHARED_PROTOTYPE_TOKEN_DATA}`: {
        if (!action.payload) {
          localDraft.tokenData = null;
          break;
        }

        const {
          share_id: shareID,
          workspace_name: workspaceName,
          workspace_id: workspaceID,
          show_metadata_filters: showMetadataFilters,
          show_files: showFiles,
          login_required: loginRequired = false,
          shared_job_info: sharedJobInfo,
          organization_id: organizationId,
        } = jwt(action.payload!) as {
          share_id: string;
          workspace_id: string;
          workspace_name: string | null;
          show_metadata_filters: boolean;
          show_files: boolean;
          login_required?: boolean;
          organization_id: string;
          shared_job_info: {
            organization_id: string;
            workspace_id: string;
            job_id: string;
            shared_job_id: string;
          };
        };
        localDraft.tokenData = {
          shareID: shareID || sharedJobInfo.shared_job_id,
          workspaceID: workspaceID || sharedJobInfo.workspace_id,
          workspaceName: workspaceName || '',
          jobID: sharedJobInfo && sharedJobInfo.job_id,
          showFiles,
          showMetadataFilters,
          loginRequired,
          organizationId,
        };
        break;
      }
      default:
        break;
    }

    return localDraft;
  });
}

export default sharedPrototypeReducer;
