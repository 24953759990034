import { getWorkspace } from '@utils/store';
import { http } from '@api/base-http';
import { MIMETypes } from '@constants/enum/common';
import { ILogParams } from '@constants/log-explorer';
import { IPipelineIndex } from '../types/pipeline-index';

export interface ICreateIndexPayload {
  name: string;
  description?: string;
  config_yaml: string;
}

export interface IIndexesData {
  limit?: number;
  page_number?: number;
  filter?: string;
  select?: string;
  field?: string;
  order?: string;
}

export interface IIndexPipelinesParams {
  indexId: string;
  limit?: number;
  pageNumber?: number;
}

export const getIndexesAPI = <T>(params?: IIndexesData, workspaceName: string = getWorkspace()) => {
  const mappedParams = {
    ...(params?.limit && { limit: params?.limit }),
    ...(params?.page_number && { page_number: params?.page_number }),
    ...(params?.filter && { filter: params?.filter }),
    ...(params?.select && { select: params?.select }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
  };

  return http.get<T>(`/api/v1/workspaces/${workspaceName}/indexes`, { params: mappedParams });
};

export const getIndexAPI = <T>(indexName: string, workspaceName: string = getWorkspace()) => {
  return http.get<T>(`/api/v1/workspaces/${workspaceName}/indexes/${indexName}`);
};

export const createIndexAPI = <T>(
  payload: ICreateIndexPayload,
  workspaceName: string = getWorkspace(),
) => {
  return http.post<T>(`/api/v1/workspaces/${workspaceName}/indexes`, payload);
};

export const updateIndexAPI = <T>(
  indexName: string,
  payload: Partial<IPipelineIndex>,
  workspaceName: string = getWorkspace(),
) => {
  return http.patch<T>(`/api/v1/workspaces/${workspaceName}/indexes/${indexName}`, payload);
};

export const enableIndexAPI = <T>(indexName: string, workspaceName: string = getWorkspace()) => {
  return http.post<T>(`/api/v1/workspaces/${workspaceName}/indexes/${indexName}/deploy`);
};

export const disableIndexAPI = <T>(indexName: string, workspaceName: string = getWorkspace()) => {
  return http.post<T>(`/api/v1/workspaces/${workspaceName}/indexes/${indexName}/undeploy`);
};

export const getIndexTotalDocumentsAPI = <T>(
  indexName: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.get<T>(`/api/v1/workspaces/${workspaceName}/indexes/${indexName}/documents-count`);
};

export const getConnectedPipelinesAPI = <T>(
  params: IIndexPipelinesParams,
  workspaceName: string = getWorkspace(),
) => {
  const { limit, indexId, pageNumber } = params;

  return http.get<T>(`/api/v1/workspaces/${workspaceName}/pipelines`, {
    params: {
      limit,
      page_number: pageNumber,
      pipeline_index_id: indexId,
    },
  });
};

export const deleteIndexAPI = <T>(indexName: string, workspaceName: string = getWorkspace()) => {
  return http.delete<T>(`/api/v1/workspaces/${workspaceName}/indexes/${indexName}`);
};

export const getIndexLogsAPI = <T>(
  indexName: string,
  params: ILogParams,
  workspaceName: string = getWorkspace(),
) => {
  return http.get<T>(`/api/v1/workspaces/${workspaceName}/indexes/${indexName}/logs`, {
    params,
  });
};

export const downloadIndexLogsAPI = <T>(
  indexName: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.get<T>(`/api/v1/workspaces/${workspaceName}/indexes/${indexName}/logs`, {
    responseType: 'blob',
    headers: {
      Accept: MIMETypes.CSV,
    },
  });
};
